<template>
 <div class="app">
  <div class="header container h-100 p-5">
    <h1 class="mb-5">Weather App</h1>
    <div class="d-flex justify-content-center h-100">
      <div class="searchbar w-50 mx-2">
        <input type="text" class="input form-control" v-model="city" placeholder="Eneter A city Name">
      </div>
      <button class="btn-search btn btn-primary" @click="searchWether">Search <i class="fas fa-search"></i></button>
    </div>
  </div>
<myWeather :city="city" v-if="showWeather"></myWeather>
 </div>
</template>

<script>
import myWeather from './components/Weather-app.vue'
export default {
components: {
  myWeather
  },
  data(){
    return{
      city:'',
      showWeather:false,
    }
  },
  methods:{
    async searchWether(){
      this.showWeather=false;
      await this.$nextTick();
      this.showWeather=true;
    }
  }
}
</script>

<style>
body{
  background-color: #121212 !important;
}
.header{
  background-color: #353c46;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 5rem;
}
.btn-search{
  background-image: linear-gradient(to right,rgb(24, 168, 173),rgb(231, 12, 165),yellow);
}
</style>
